.space-xxs, .spacer-xxs {
  display: block;
  margin-top: 5px;
}

 

.space-xs, .spacer-xs {
  display: block;
  margin-top: 20px;
}

.space-sm, .spacer-sm {
  display: block;
  margin-top: 10px;
}

.space-md, .spacer-md {
  display: block;
  margin-top: 30px;
}

.space-lg, .spacer-lg {
  display: block;
  margin-top: 100px;
} 