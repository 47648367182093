.mainnav {
	display: block;
} 

.mainnav ul#menu-main {
	list-style-type: none;
	display: inline-block;
	font-weight: normal;
    font-family: Varela Round,arial,sans-serif;
    font-weight: 400;
    font-style: normal;
    line-height: 1.6em;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.mainnav ul#menu-main li.menu-item {
	display: inline-block;
	margin-left:  10px;
	margin-right: 10px; 
	color: #b5b5b5;
}

.mainnav ul#menu-main li.menu-item a { 
	color: #b5b5b5;
	text-decoration: none;
}
  

.mainnav .current-menu-item a, .mainnav .current-page-ancestor, .mainnav .current-page-ancestor a {
	color: #ff2b0f !important;
}


.mainnav ul#menu-main li.chart_icon.menu-item, 
.mainnav ul#menu-main li.chart_icon.menu-item a {
	color: green !important;
	 font-size: 0px;
}
 
 .mainnav ul#menu-main li.chart_icon.menu-item a::after  {
	content: "\f218";
	font-size: 12px;
	 
	font-family: FontAwesome;
 }



.hamburger {
	display: none; 
	height: 40px; 
	margin: 0 auto;  
    cursor: pointer; 
    margin: 0;
    font-size: 16px !important;
    color: #b5b5b5;
} 
 
 

.mobilemenu {
	display: none;
	background-color: white;
 	height: 0px;  
 	-webkit-transition: all 3s ease;
    -moz-transition: all 3s ease;
    -o-transition: all 3s ease;
    transition: all 3s ease; 
} 

.showmenu {
	height: auto; 
	display: block; 
	list-style-type: none; 
	font-weight: normal;
    font-family: Varela Round,arial,sans-serif;
    font-weight: 400;
    font-style: normal;
    line-height: 2em;
    text-transform: uppercase;
    letter-spacing: 2px; 
    color: rgba(181,181,181,.7);
    font-size: 16px;
    text-decoration: none;
} 

.mobilemenu ul#menu-main-1 {
	list-style-type: none;
	display: block;
	font-weight: normal;
    font-family: Varela Round,arial,sans-serif;
    font-weight: 400;
    font-style: normal;
    line-height: 2em;
    text-transform: uppercase;
    letter-spacing: 2px; 
    color: rgba(181,181,181,.7);
    font-size: 16px;
    text-decoration: none;
}

.mobilemenu ul#menu-main-1 li.menu-item {
	display: block; 
	color: rgba(181,181,181,.7);
}

.mobilemenu ul#menu-main-1 li.menu-item:hover {
	display: block;
	color: rgba(181,181,181,1);
}

.mobilemenu ul#menu-main-1 li.menu-item a { 
	color: rgba(181,181,181,.7);
	text-decoration: none;
}
 
.mobilemenu ul#menu-main-1 li.menu-item a:hover {
	display: block; 
	color: rgba(181,181,181,1);
} 

.mobilemenu .current-menu-item a, .mobilemenu .current-page-ancestor, .mobilemenu .current-page-ancestor a {
	color: #ff2b0f !important;
} 


@media screen and (max-width:768px) {  

	.mainnav ul#menu-main li.menu-item {
		display: inline-block;
		margin-left:  3px;
		margin-right: 3px;  
	} 
} 



@media screen and (max-width: 640px) {
  
	.hamburger {
		display: block; 
	}

	.mainnav {
		 display: none; 
	} 
} 