 .gitem{ 

}

.gitem:nth-child(even){ 
	padding-left: 150px;
	margin-right: 0px;
}

.gallery_overview_item { 
	position: relative;
	display: inline-block; 
	width: 100%; 
	margin-bottom: 12px; 
	padding: 0px; 
	//background-color: orange;
} 

 
.gallery_overview_title {
	position: relative;
	text-align: left; 
	margin: 0; 
	//background-color: blue; 
	width: 100%; 
	height: 40px;
	display: inline-block; 
	color: #353535; 
}

.gallery_overview_title, .gallery_overview_title a {
		text-transform: none;
    font-family: "Georgia","serif";
    font-weight: bolder;
    font-style: normal;
    line-height: 1.7em;
    font-size: 15px;
    text-decoration: none;
    margin: 0 0 1.7em;
    color: #777; 
    text-transform: capitalize;
}


.gallery_overview_title span, .gallery_overview_title a span {
	text-transform: none;
    font-family: "Georgia","serif";
    font-weight: 600;
    font-style: normal;
    line-height: 1.7em;
    font-size: 15px;
    text-decoration: none;
    margin: 0 0 1.7em;
    color: #777; 

}  

 


.gallery_overview_thumb { 
	position: relative;
 	width: 100%;
	height: 189px;
	display: inline-block;
	padding: 0px;
	margin: 0px; 
	opacity: 1; 
	background-size: auto 100%; 
	background-repeat: no-repeat;
	background-position: left top; 
	//background-color: red;
} 

.gallery_overview_thumb:hover, .carousel-thumb:hover, .carousel-thumb-big:hover {
	cursor: pointer;
}
 
@media screen and (max-width: 992px) {
  
	.gallery_overview_title {
		text-align: left; 
		margin: 0;  
		width: 100%; 
		height: 40px;
		display: inline-block;  
		color: #353535; 
	}
	.gallery_overview_thumb { 
	 	width: 100%;
		height: 159px;
		display: inline-block;
		padding: 0px;
		margin: 0px; 
		opacity: 1; 
		background-size: auto 100%; 
		background-repeat: no-repeat;
		background-position: left top; 
	}  

} 

@media screen and (max-width:768px) { 

	.gitem {  
		margin-left: 0px;
		padding-left: 0px;
	}

	.gitem:nth-child(even){ 
		padding-left: 0px;
		background-color: white;
		margin-left: 15px !important;
	} 
} 

 