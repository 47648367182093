.woocommerce-error, .woocommerce-info, .woocommerce-message {
    padding: 1em 2em 1em 3.5em;
    margin: 0 0 2em;
    position: relative;
    background-color: #fff;
    color: #515151;
    border-top: 3px solid #ff2b0f !important;
    list-style: none outside;
    width: auto;
    word-wrap: break-word;
}

.woocommerce-message {
    border-top-color: #ff2b0f !important;
    background-color: #fff !important;
}

a.button.alt, .woocommerce button.button.alt, .woocommerce input.button.alt {
    background-color: #A2A4A3 !important;
    color: #fff;
    -webkit-font-smoothing: antialiased;
}

a.button.alt:hover, .woocommerce button.button.alt:hover, .woocommerce input.button.alt:hover {
    background-color: #ff2b0f !important;
    color: #fff;
    -webkit-font-smoothing: antialiased;
}

.single_add_to_cart_button.button .alt {
	display: inline-block;
	float: left;
}


a.added_to_cart.wc-forward { 
    bottom: 0; 
    position: absolute; 
    display: block; 
    bottom: 0px;
}

.woocommerce ul.products li.product, 
.woocommerce-page ul.products li.product { 
    min-height: 295px !important; 
    margin-bottom:10px; 
}

a.button.add_to_cart_button, 
a.button.product_type_simple { 
    bottom: 30px; 
    position: absolute; 
}

.woocommerce #respond input#submit, .woocommerce a.button, .woocommerce button.button, .woocommerce input.button {
    font-size: 100%;
    margin: 0;
    line-height: 1;
    cursor: pointer;
    position: relative;
    text-decoration: none;
    overflow: visible;
    padding: .618em 1em;
    font-weight: 700;
    border-radius: 0px !important;
    left: auto;
    color: #515151;
    background-color: #ebe9eb;
    border: 0;
    display: inline-block;
    background-image: none;
    box-shadow: none;
    text-shadow: none;
}

input[type="text"], input[type="email"], input[type="url"], input[type="password"], input[type="search"], input[type="number"], input[type="tel"], input[type="range"], input[type="date"], input[type="month"], input[type="week"], input[type="time"], input[type="datetime"], input[type="datetime-local"], input[type="color"], textarea {
    color: #666;
    background: #fff;
    background-image: -webkit-linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
    border: 1px solid #bbb;
    -webkit-border-radius: 3px;
    border-radius: 0px !important;
    display: block;
    padding: 0.7em;
    width: 100%;
}


#cart_icon {
    position: fixed;
    right: 10px;
    top: 10px;
    font-size: 24px; 
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    background-color: #cfcfcf;
}


#cart_icon:hover  {
   background-color: #eaeaea;
}

#cart_icon:hover  {
    color: #ff2b0f;
}

a #cart_icon  {
    color: #fff;
}

.gallery_item {
    height: 350px;
}


.woocommerce ul.products li.product .price {
    color: #77a464;
    display: block;
    font-weight: 400;
    margin-bottom: 1.5em !important;
    font-size: .857em;
}

.price span .woocommerce-Price-amount {
    margin-bottom: 1.5em !important;
}