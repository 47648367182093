 

 p {  
    text-transform: none;
    font-family: "Georgia","serif";
    font-weight: 400;
    font-style: normal;
    line-height: 1.7em;
    font-size: 15px;
    text-decoration: none;
    margin: 0 0 1.7em;
    color: #777;
}

.woo-content p {
    text-transform: none;
    font-family: "Georgia","serif";
    font-weight: 400;
    font-style: normal;
    line-height: 1.7em;
    font-size: 15px;
    text-decoration: none;
    margin: 0 0 1.7em;
    color: #777;
}

.contact-page {

} 

a {
    color: #8e8888;
    text-decoration: none;
}



.container a {
     color: #8e8888;
     text-decoration: none;
  /*  color: #ff2b0f; */
} 


.cff-author-text a {
    color: #777 !important;
    font-size: 16px !important;
    font-family: "Georgia","serif" !important;
    font-weight: 400 !important;
    text-transform: capitalize !important;
}

.cff-author p.cff-page-name a {
    color: #222 !important; 
    font-size: 20px !important;
    font-family: "Georgia","serif" !important;
    font-weight: 400 !important;
    text-transform: capitalize !important;
}

.cff-item {
    margin-bottom: 60px !important;
}

#cff .cff-item {
    float: left;
    width: 100%;
    clear: both;
    padding: 50px 0px 15px 0px !important;
    margin: 0;
    border-bottom: 1px solid #ddd !important;
}

p.cff-link-title  a {
    color: #777 !important;
    font-size: 22px !important;
    font-family: "Georgia","serif" !important;
    font-weight: 400 !important;
    text-transform: capitalize !important;
}


h1, h2, h3 {
    margin-top: 0;
    font-family: Varela Round,arial,sans-serif; 
    padding-bottom: 1.5em;
    margin-bottom: 1.5em; 
    color: #222; 
    font-weight: 400  !important;
}

h1 { 
    font-size: 18px;    
}

h2 {  
    margin-top: 0;
    font-family: Varela Round,arial,sans-serif;
    font-size: 18px   !important; 
    padding-bottom: 1.5em;
    margin-bottom: 0px; 
    color: #222; 
    text-transform: uppercase; 
    font-weight: 400  !important; 
}

h3 { 
    font-size: 14px;    
}

h4, h5 {
    font-weight: 400  !important;
    color: #222 !important;
    text-transform: uppercase; 
}
 
 

.entry-header .entry-title {
    margin-bottom: 0px;
    display: none;
}

h1.logo {
    font-family: Varela Round,arial,sans-serif;
    line-height: 1.2em;
    font-size: 20px;
    letter-spacing: 3px;
    font-family: calluna-sans;
    font-weight: 400;
    font-style: normal;
    line-height: 1.4em;
    font-size: 30px;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 4px;
}

.project-title {
    margin-top: 0;
    font-family: Varela Round,arial,sans-serif;
    font-size: 18px; 
    padding-bottom: 1.5em;
    margin-bottom: 0px;
    display: inline-block;
    color: #222; 
    text-transform: uppercase; 
}