input[type=text], select {
    width: 100%;
    max-width: 300px;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

input[type=submit] {
    width: 100%;
    background-color: #4CAF50;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer; 
}

input[type=submit]:hover {
    background-color: #45a049;
}

label { 
    padding: 12px 20px;
    margin: 0;
    display: inline-block;
    border: none;
    width: 100%;
    max-width: 200px;
    border-radius: 4px;
    box-sizing: border-box;
} 

 

input[type=checkbox], input[type=radio] {
    box-sizing: border-box;
    padding: 0;
    width: 20px;
}


#ship-to-different-address label {
    max-width: 600px;
    text-align: left;
}

textarea {
    width: 100%;
    min-width: 200px;
}

.woocommerce-input-wrapper {
    width: 100%;
}

.quantity {
    padding: 2px;
    width: 50px;
    display: block;
    padding: 0px;
    float: left;
    border: none;
}


input[type="number"] {
    padding: 4px !important;
    margin: 0px !important;
    margin-right: 2px !important;
    display: inline-block;
    border: 1px solid #ccc !important;
    border-radius: 4px; 
    box-sizing: border-box;
}
 