.wpcf7 {
	padding-left: 0px;
	margin-left: 0px;
}

.wpcf7 input[type="text"], .wpcf7 input[type="email"] {
	text-transform: none;
    font-family: "Georgia","serif";
    font-weight: 400;
    font-style: normal;
    line-height: 1.7em;
    font-size: 15px;
    text-decoration: none; 
    color: #777;
    display: block;
    background: #fafafa;
    width: 100%;
    min-width: 100%; 
	margin-left: 0px;
}
 

.wpcf7 textarea {
    background: #fafafa;
    color: #000;
    width: 100%;
  }
 

.wpcf7-form {
	width: 100%;
	display: block;
}

.wpcf7-form label {
	width: 100%;
	display: block;
		text-transform: none;
    font-family: "Georgia","serif";
    font-weight: 400;
    font-style: normal;
    line-height: 1.7em;
    font-size: 15px;
    text-decoration: none; 
    color: #777;
    display: block;
    margin-bottom: 0px; 
    float: left;
    min-width: 100%;
   	padding-left: 0px;
	margin-left: 0px;
}

 

.wpcf7-form input[type="submit"]   {
	background-color: white;
	 font-family: 'Varela Round', sans-serif;
    color: #272727;
    width: 134px;
    max-width: 134px;
    border: 2px solid  #272727; 
    font-weight: 400;
    font-style: normal;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 600;
    padding-top: 15px;
    padding-bottom: 15px;
}

.wpcf7-form input[type="submit"]:hover   {
	background-color: #272727; 
    color: white; 
    opacity: 1;
    border: 2px solid #272727; 
}




@media screen and (max-width:768px) { 

   .wpcf7 {
    padding-left: 4px;
    margin-left: 4px;
}

.wpcf7 input[type="text"], .wpcf7 input[type="email"] { 
    width: 90%;
    min-width: 90%; 
    margin-left: 4px;
}
 

.wpcf7 textarea { 
    color: #000;
    width: 90%;
  }
 

.wpcf7-form {
    width: 90%;
    display: block;
}

.wpcf7-form label {
    width: 90%; 
    min-width: 90%;
    padding-left: 4px;
    margin-left: 4px;
}
} 
