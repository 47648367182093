.slick-dots li   { 
    width: 8px !important;
    height: 8px !important;
    display: block;
    background: #A2A4A3;
    cursor: pointer;
    text-indent: -9999px;
    border-radius: 50%;
    font-size: 0;
}

.slick-dotted.slick-slider {
    margin-bottom: 0px !important; 
}

.gallery_item {
    width: 100%;
    background-color: white; 
    height: 400px;
    text-align: left;
}
 
.gallery_item img {
      max-width: 100%;
    max-height: 100%;
   // margin: 0 auto;
}

.slick-controls {
    background-color: white;
    width: 100%;
} 


ul.custom-dots   {
    width: 136px;
    position: relative;
     
    display: block;
    background-color: white;
}
.col-arrows {
    width: 40%;
    position: relative; 
    display: block;
    background-color: white;
}

ul.custom-dots li {
    width: 8px;
    height: 8px;
    list-style-type: none;
    display: inline-block;
    margin-right: 4px;
}

ul.custom-dots li button {
    width: 8px;
    height: 8px;
    list-style-type: none;
    display: inline-block;
    padding: 0px;
    background-color: #A2A4A3;
    font-size: 0px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}

ul.custom-dots li.slick-active button  {
    background-color: #E6E5E4; 
}

ul.custom-dots li:hover button {
    background-color: #A71930; 
}

ul.custom-dots li.slick-active:hover button  {
    background-color: #E6E5E4; 
}

button {
    border: none;
    outline: none;
}
 

.slick-prev-custom,  .slick-next-custom { 
    position: relative;
    background-color: white;
    width: 74px;
    height: 22px;
    text-decoration: none;
    cursor: pointer; 
    color: #A2A4A3;
    font-size: 14px;
    padding: 4px;
    border: none; 
    font-weight: 500;
}

.slick-prev-custom:focus,  .slick-next-custom:focus{ 
    background-color: white;
}


button.slick-prev-custom:hover,  button.slick-next-custom:hover {  
    color: #A71930;
    background-color: white;
}

