.carousel-thumb {
	width: 50px;
	height: 70px;
	display: block;
	padding: 0px;
	margin: 0px; 
	opacity: 0.5;
	background-size: 60%;
	background-repeat: no-repeat;
	background-position: center center;
}

.slick-active .carousel-thumb {
	//background-color: red;
	color: #fff; 
}

 

 .slick-center  .carousel-thumb {
	//background-color: green;
	color: #fff;
	opacity: 1;
}

 

.carousel-thumb-big {
	width: 450px;
	height: 450px; 
	text-align: center;
	margin: 0 auto;
	margin-bottom: 40px;
	background-size: auto 100%;
	background-repeat: no-repeat;
	background-position: center center;
}


@media screen and (max-width:768px) { 
	.carousel-thumb-big {
		width: 250px;
		height: 250px;
		text-align: center;
		margin: 0 auto;
		margin-bottom: 10px;
		background-size: auto 80%;
		background-repeat: no-repeat;
		background-position: center center;
	} 
} 
