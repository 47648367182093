.intro {
	width: 100%; 
	background-color: white;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0; 
	margin: 0;
	overflow: hidden;
}  

.intro_img_holder { 
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: -400px;
	margin-left: -400px; 
	width: 800px;
	height: 800px;
	display: block; 
	text-align: center; 
	background-size: 75%;
	background-repeat: no-repeat;
	background-position: center center;
	opacity: 0;
}

.entersite_btn {
	color: #bfbfbf;
	font-size: 12px;
}

.entersite_btn, .entersite_btn a {
    font-size: 12px;
    color: #bfbfbf;
}

.entering  {
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: -325px;
	margin-left: -325px; 
	padding-top: 0px;
	width: 650px;
	height: 650px;
	display: block; 
	text-align: center;
	background-color: white;
}

.entersite_btn:hover {
	cursor: pointer;
}

.percentage {
	position: absolute;
	list-style-type: 0px;
	top: 50%;
	width: 100%;  
	height: 2px; 
    padding: 0px;
    margin: 0;
	background-color: white;
} 

.progress { 
    height: 2px !important;
    padding: 0px;
    margin: 0;
    overflow: hidden;
    font-size: 12px;
    background-color: red; //bdbdbd 
    border-radius: 0;
    border: none;
    height: 1px;
}

.logo_intro { 
    margin-bottom: 30px;
}