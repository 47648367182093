 html, body { 
    font-family: 'Varela Round', sans-serif;
    line-height: 24px;
    font-size: 14px;
    font-weight: 300;
    color: #777777;
    background-color: white;
    background-color: #e8e8e8;
}


html {
  height: 100%;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}


body {
  position: relative;
  margin: 0;
  padding-bottom: 6rem;
  min-height: 100%; 
}
 

.footer {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0; 
    text-align: center; 
    padding-top: 20px;
    padding-bottom: 35px;
}

.footer p {
    font-weight: normal;
    font-family: Varela Round,arial,sans-serif;
    font-weight: 400;
    font-style: normal;
    line-height: 1.6em;
    font-size: 12px;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 2px;
}


.row {
  margin-left: 0px;
  margin-right: 0px;
}

.logo { 
    margin-bottom: 60px;
}

 
@import 'footer';
@import 'typography';  
@import 'jsgallery';  
@import 'gallery';
@import 'spacers'; 
@import 'intro'; 
@import 'slick'; 
@import 'woccommerce';  
@import 'form';  
@import 'contact_form_7';
@import 'navigation';


.canvas {
    max-width: 1000px;
    margin: 0 auto;
    width: 100%;
    padding: 75px 75px 0;
}


@media screen and (max-width:768px) {  

  .canvas { 
    padding: 55px 15px 0;
  }
} 

